import { Box, Heading, HStack } from '@chakra-ui/react'

import { OrganizationSwitcher } from '@clerk/clerk-react'
import { useCurrentUser } from '../hooks/use-current-user'
import { SchemamapLogo } from '@ui/lib/src/logo/schemamap-logo'

export const WorkspacesMenu: React.FC = (props) => {
  const { tenant } = useCurrentUser()

  if (!tenant) {
    return (
      <HStack spacing={4} ml={2} mt={1}>
        <Box width="24px">
          <SchemamapLogo />
        </Box>
        <Heading size="xs">Schemamap.io - Demo</Heading>
      </HStack>
    )
  }
  return (
    <Box width={'full'} ml={2}>
      <OrganizationSwitcher
        hidePersonal
        createOrganizationMode={'navigation'}
        createOrganizationUrl={'/getting-started'}
      />
    </Box>
  )
}
