import * as React from 'react'

import { Spacer, useBreakpointValue } from '@chakra-ui/react'

import {
  FiHome,
  FiCheckCircle,
  FiHelpCircle,
  FiUsers,
  FiTable,
  FiDatabase,
  FiSlack,
  FiFileText,
  FiShare2,
} from 'react-icons/fi'

import {
  Command,
  Resizer,
  ResizeHandle,
  ResizeHandler,
} from '@saas-ui-pro/react'

import { useActivePath, useRouter } from '@app/nextjs'

import {
  Sidebar,
  SidebarProps,
  SidebarOverlay,
  SidebarSection,
  SidebarToggleButton,
  NavItem,
  NavItemProps,
  NavGroup,
  useLocalStorage,
  useHotkeysShortcut,
  useSnackbar,
} from '@saas-ui/react'

import { ElectronNav, useHelpCenter } from '@ui/lib'

import { BillingStatus } from './billing-status'
import { WorkspacesMenu } from './workspaces-menu'
import { UserMenu } from './user-menu'

import { SlackConnectUrl } from '@app/features/settings/components/slack-connect-button'
import { useCurrentUser } from '../hooks/use-current-user'

import { useMemo } from 'react'
import { ProjectsMenu } from './projects-menu'
import {
  SiGooglesheets,
  SiMicrosoftexcel,
  SiPostgresql,
  SiSalesforce,
} from 'react-icons/si'
import { usePath, useProjectPath } from '../hooks/use-path'
import { FaDiscord } from 'react-icons/fa'

export interface AppSidebarProps extends SidebarProps {}

export const AppSidebar: React.FC<AppSidebarProps> = (props) => {
  const { projects, project, isLoading, tenant } = useCurrentUser()
  const help = useHelpCenter()

  const slackUrl = useMemo(() => {
    if (!tenant?.id) return null
    return SlackConnectUrl(tenant.id)
  }, [tenant?.id])

  const [width, setWidth] = useLocalStorage('app.sidebar.width', 300)

  const { variant, colorScheme } = props
  const isCondensed = variant === 'condensed'
  const snackbar = useSnackbar()

  const onResize: ResizeHandler = ({ width }) => {
    setWidth(width)
  }
  const dashboardPath = usePath()
  const projectsPath = usePath('projects')
  const schemaOverviewPath = useProjectPath('schema-overview')
  const schemaOverviewActivePath = useActivePath('schema-overview', {
    end: false,
  })
  const tenantsPath = useProjectPath('tenants')

  const tenantsActivePath = useActivePath('tenants', { end: false })
  const mdesPath = useProjectPath('mdes')
  const mdesActivePath = useActivePath('mdes', { end: false })
  const googleSheetsPath = useProjectPath('google-sheets')
  const googleSheetsActivePath = useActivePath('google-sheets', { end: false })
  const postgresPath = useProjectPath('postgres')
  const postgresActivePath = useActivePath('postgres', { end: false })

  const hasDatasource = useMemo(
    () => project?.environments?.some((e) => e.datasources.length > 0),
    [project?.environments],
  )

  return (
    <Resizer
      defaultWidth={width}
      onResize={onResize}
      isResizable={useBreakpointValue(
        { base: false, lg: true },
        { fallback: 'lg' },
      )}
    >
      <Sidebar variant={variant} colorScheme={colorScheme} {...props}>
        <SidebarToggleButton />
        <ElectronNav />
        <SidebarSection direction="column">
          <WorkspacesMenu />
          <Spacer />
          <UserMenu />
        </SidebarSection>
        {/*  <Box px={4}>
          {isCondensed ? (
            <IconButton icon={<FiSearch />} aria-label="Search" />
          ) : (
            <GlobalSearchInput />
          )}
        </Box> */}
        <SidebarSection overflowY="auto" flex="1">
          <NavGroup>
            <AppSidebarLink
              href={dashboardPath}
              label="Dashboard"
              icon={<FiHome />}
              hotkey="navigation.dashboard"
            />
            <AppSidebarLink
              href={projectsPath}
              isActive={useActivePath('projects', { end: false })}
              label="Projects"
              icon={<FiCheckCircle />}
              hotkey="navigation.projects"
            />
          </NavGroup>
          <ProjectsMenu title="Select Project" />
          {!isLoading && hasDatasource ? (
            <NavGroup title="Project-specific" isCollapsible>
              <AppSidebarLink
                href={schemaOverviewPath}
                isActive={schemaOverviewActivePath}
                label="Schema Overview"
                icon={<FiTable />}
                hotkey="navigation.schemaOverview"
              />
              <AppSidebarLink
                href={tenantsPath}
                isActive={tenantsActivePath}
                label="Tenants"
                icon={<FiUsers />}
                hotkey="navigation.tenants"
              />
              <AppSidebarLink
                href={mdesPath}
                isActive={mdesActivePath}
                label="Master Data Entities"
                icon={<FiShare2 />}
                hotkey="navigation.mdes"
              />
              <NavGroup title="Integrations" mt={6} isCollapsible>
                <AppSidebarLink
                  href={postgresPath}
                  isActive={postgresActivePath}
                  label="Postgres"
                  icon={<SiPostgresql size={'20px'} />}
                  hotkey="navigation.postgres"
                />

                <NavGroup
                  title="Coming soon..."
                  isCollapsible
                  defaultIsOpen={false}
                  mt={2}
                >
                  <AppSidebarLink
                    href={googleSheetsPath}
                    isActive={googleSheetsActivePath}
                    label="Google Sheets"
                    icon={<SiGooglesheets size={'20px'} />}
                    hotkey="navigation.googleSheets"
                  />
                  <NavItem icon={<FiFileText size={'20px'} />}>CSV</NavItem>
                  <NavItem icon={<SiMicrosoftexcel size={'20px'} />}>
                    Excel
                  </NavItem>
                  <NavItem icon={<SiSalesforce size={'20px'} />}>
                    Salesforce
                  </NavItem>
                </NavGroup>
              </NavGroup>
            </NavGroup>
          ) : undefined}
          <Spacer />

          <NavGroup>
            {tenant && slackUrl && (
              <NavItem
                href={slackUrl}
                target="_blank"
                color="sidebar-muted"
                icon={<FiSlack />}
              >
                Add to Slack
              </NavItem>
            )}
            <NavItem
              href={'https://discord.schemamap.io/'}
              color="sidebar-muted"
              target="_blank"
              icon={<FaDiscord />}
            >
              Join the Discord
            </NavItem>
            <NavItem
              onClick={() => help.open()}
              color="sidebar-muted"
              icon={<FiHelpCircle />}
            >
              Help &amp; support
            </NavItem>
          </NavGroup>
        </SidebarSection>

        {isCondensed ? (
          <SidebarSection>
            <UserMenu />
          </SidebarSection>
        ) : (
          <SidebarSection>
            {!isLoading && hasDatasource && <BillingStatus />}
          </SidebarSection>
        )}

        <SidebarOverlay />
        <ResizeHandle />
      </Sidebar>
    </Resizer>
  )
}

interface AppSidebarlink extends NavItemProps {
  hotkey: string
  href: string
  label: string
}

const AppSidebarLink: React.FC<AppSidebarlink> = (props) => {
  const { href, label, hotkey, ...rest } = props
  const { push } = useRouter()
  const isActive = useActivePath(href)

  const command = useHotkeysShortcut(hotkey, () => {
    push(href)
  }, [href])

  return (
    <NavItem
      href={href}
      isActive={isActive}
      {...rest}
      tooltipProps={{
        label: (
          <>
            {label} <Command>{command}</Command>
          </>
        ),
      }}
    >
      {label}
    </NavItem>
  )
}
