import { gql } from './gql'

export const CREATE_DEMO_PG_DATASOURCE = gql(`
  mutation CreateDemoPgDatasource(
    $connectionString: String!
  ) {
    createDemoPgDatasource(
      connectionString: $connectionString
    ) {
      datasourceId
      datasource {
        id
        host
        port
        database
        createdAt
        updatedAt
      }
    }
  }
`)
