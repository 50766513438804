import React from 'react'
import { useWorkspace } from './use-workspace'
import { useCurrentUser } from './use-current-user'

/**
 * Returns the path including the app base path and workspace.
 * @param path
 * @returns string The router path
 */
export const usePath = (path = '/') => {
  const workspace = useWorkspace()
  return React.useMemo(
    () =>
      `/${workspace ?? 'demo'}/${path}`
        .replace(/\/\//, '/')
        .replace(/\/$/, '') ?? '/',
    [path, workspace],
  )
}

export const useProjectPath = (path = '/') => {
  const basePath: string = usePath()
  const { project } = useCurrentUser()

  return React.useMemo(
    () =>
      `/${basePath}/projects/${project?.slug}/${path}`
        .replace(/\/\//, '/')
        .replace(/\/$/, '') ?? '/',
    [basePath, path, project?.slug],
  )
}
