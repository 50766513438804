import { useAuth } from '@clerk/nextjs'
import { setContext } from '@apollo/client/link/context'
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'

import React, { useEffect, useMemo } from 'react'
import { useRouter } from '@app/nextjs'

export const getAPIUrl = (): string => {
  const ROOT_URL = process.env.NEXT_PUBLIC_GRAPHQL_API_URL
  if (ROOT_URL) {
    return ROOT_URL
  }
  throw new Error('No NEXT_PUBLIC_GRAPHQL_API_URL configured.')
}

export const ApolloProviderWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { getToken, orgId } = useAuth()
  const router = useRouter()

  const apolloClient = useMemo(() => {
    const authMiddleware = setContext(async (req, { headers }) => {
      const token = await getToken({ template: 'hasura' })
      if (!token) {
        return headers
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    })

    const httpLink = new HttpLink({
      uri: getAPIUrl(),
    })

    const removeTypenameLink = removeTypenameFromVariables({
      except: {
        // json: KEEP,
      },
    })

    return new ApolloClient({
      link: from([authMiddleware, removeTypenameLink, httpLink]),
      cache: new InMemoryCache(),
    })
  }, [getToken])

  const apolloRefetcher = useMemo<() => void | undefined>(() => {
    return () => {
      apolloClient.refetchQueries({ include: 'active' })
    }
  }, [apolloClient])

  // Implements: https://github.com/apollographql/apollo-feature-requests/issues/422
  useEffect(() => {
    if (!apolloRefetcher) return

    window.addEventListener('focus', apolloRefetcher)

    return () => {
      window.removeEventListener('focus', apolloRefetcher)
    }
  }, [apolloRefetcher])

  useEffect(() => {
    // Eagerly refetch all active queries on route changes
    apolloRefetcher?.()
  }, [apolloRefetcher, router])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
