import {
  Button,
  HStack,
  Text,
  Avatar,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  AvatarProps,
  Portal,
  MenuGroup,
  Center,
  MenuItem,
  IconButton,
} from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

import { useCurrentUser } from '../hooks/use-current-user'
import { useRouter } from '@app/nextjs'
import { ChevronDownIcon } from '@saas-ui/react'

const ProjectLogo: React.FC<AvatarProps> = (props) => {
  const { src, ...rest } = props
  return <Avatar src={src} size="xs" borderRadius="full" {...rest} />
}

export interface ProjectsMenuProps {
  title: string
  children?: React.ReactNode
}

export const ProjectsMenu: React.FC<ProjectsMenuProps> = (props) => {
  const { title = 'Projects', children } = props
  const router = useRouter()
  const { projects, project, projectId, setProjectId } = useCurrentUser()

  if (projects && projects?.length <= 1) {
    return null
  }
  return (
    <Menu>
      <MenuButton
        as={Button}
        px={0}
        width={'full'}
        rightIcon={<ChevronDownIcon mr={2} />}
        variant="secondary"
        aria-label="Project menu"
      >
        <Center alignItems="center" justifyContent={'center'}>
          {/* <ProjectLogo name={project?.name} src={undefined} mr={2} /> */}
          <Text>{project?.name || 'Create a project'}</Text>
        </Center>
      </MenuButton>
      <Portal>
        {/* Wrap the menu in a portal so that the color scheme tokens get applied correctly.  */}
        <MenuList zIndex={['modal', null, 'dropdown']}>
          <MenuGroup title={title}>
            {projects?.map(({ id, name, ...props }) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  icon={<ProjectLogo name={name} src={undefined} />}
                  onClick={() => setProjectId(id)}
                >
                  <HStack>
                    <Text>{name}</Text>
                    <Spacer />
                    {id === projectId ? <FiCheck /> : null}
                  </HStack>
                </MenuItem>
              )
            })}
          </MenuGroup>
          {children}
        </MenuList>
      </Portal>
    </Menu>
  )
}
