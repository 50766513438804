import * as React from 'react'

import {
  Flex,
  FlexProps,
  Container,
  Text,
  HTMLChakraProps,
  ThemingProps,
} from '@chakra-ui/react'

import { AppShell, AppShellProps, HotkeysConfig } from '@saas-ui/react'
import { useLocation, useRouter } from '@app/nextjs'

import { authProviders } from '@app/config'
import { SettingsSidebar } from '@app/features/settings/components/sidebar'

import { AppSidebar } from '../components/sidebar'

import { Logo, Link } from '@ui/lib'

import { PublicLayout } from './public-layout'
import { BillingProvider } from '@saas-ui-pro/billing'
import { useInitApp } from '../hooks/use-init-app'
import { CurrentUserProvider } from '../hooks/use-current-user'
import {
  AuthFormContainer,
  AuthFormOptions,
  AuthFormTitle,
  Providers,
  useAuth,
} from '@saas-ui/auth'
import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react'

interface AuthFormWrapperProps
  extends AuthFormOptions,
    Omit<HTMLChakraProps<'div'>, 'title'>,
    ThemingProps<'SuiAuthForm'> {}

export const AuthFormWrapper: React.FC<AuthFormWrapperProps> = (props) => {
  const {
    providers,
    title,
    providerLabel = 'Continue with',
    dividerLabel = 'or continue with',
    footer,
    children,
    redirectUrl,
    ...rest
  } = props

  const { logIn } = useAuth()

  const signInWith = (provider: string) => {
    return logIn({ provider }, { redirectTo: redirectUrl })
  }

  return (
    <AuthFormContainer {...rest}>
      {typeof title === 'string' ? (
        <AuthFormTitle>{title}</AuthFormTitle>
      ) : (
        title
      )}
      {providers && (
        <>
          <Providers
            providers={providers}
            labelPrefix={providerLabel}
            onSignIn={signInWith}
          />
        </>
      )}

      {children}

      {footer}
    </AuthFormContainer>
  )
}

export const AgreeToTerms = ({ action }: { action: string }) => {
  return (
    <Text fontSize="sm" mt={8} textAlign="center">
      By {action} you agree to our{' '}
      <Link href={'https://schemamap.io/terms'} isExternal variant={'muted'}>
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link href={'https://schemamap.io/privacy'} isExternal variant={'muted'}>
        Privacy Policy
      </Link>
      .
    </Text>
  )
}

export const AuthPage: React.FC<{ title: string; action: string }> = (
  props,
) => {
  return (
    <AuthLayout>
      <Container>
        <Logo margin="0 auto" mb="12" />
        <AuthFormWrapper providers={authProviders} title={props.title}>
          <AgreeToTerms action={props.action} />
        </AuthFormWrapper>
      </Container>
    </AuthLayout>
  )
}

/**
 * Wrapper component for Authenticated pages.
 *
 * Loads the minimal required user data for the app and
 * redirects to authentication screens when the user isn't authenticated.
 */
export const Authenticated: React.FC<React.PropsWithChildren> = (props) => {
  const router = useRouter()

  if (router.pathname.startsWith('/demo')) {
    return props.children
  }

  return (
    <>
      <SignedIn>{props.children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  )
}

/**
 * Layout for authentication screens (login/signup/etc...)
 */
export const AuthLayout: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex minH="100vh" align="center" justify="center" {...rest}>
      {children}
    </Flex>
  )
}

interface AuthenticatedLayoutProps extends AppShellProps {
  hotkeys?: HotkeysConfig
}

/**
 * Base layout for authenticated pages.
 */
export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
  hotkeys,
  sidebar,
  ...rest
}) => {
  const { billing } = useInitApp()
  const router = useRouter()
  //if (router.pathname.startsWith('/demo')) {
  return (
    <BillingProvider value={billing}>
      <CurrentUserProvider>
        <AppShell sidebar={sidebar} {...rest}>
          {children}
        </AppShell>
      </CurrentUserProvider>
    </BillingProvider>
  )
  /*}

  return (
    <BillingProvider value={billing}>
      <Authenticated>
        <CurrentUserProvider>
          <AppShell sidebar={sidebar} {...rest}>
            {children}
          </AppShell>
        </CurrentUserProvider>
      </Authenticated>
    </BillingProvider>
  )
    */
}

/**
 * Default authenticated layout with sidebar.
 */
export const DefaultLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
  sidebar = <AppSidebar />,
  ...rest
}) => {
  return (
    <AuthenticatedLayout sidebar={sidebar} {...rest}>
      {children}
    </AuthenticatedLayout>
  )
}

/**
 * Layout for settings pages.
 */
export const SettingsLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
  ...rest
}) => {
  return (
    <AuthenticatedLayout {...rest} sidebar={<SettingsSidebar />}>
      {children}
    </AuthenticatedLayout>
  )
}

/**
 * Fullscreen layout, for functionality that requires extra focus, like onboarding/checkout/etc.
 */
export const FullscreenLayout: React.FC<AuthenticatedLayoutProps> = ({
  children,
  ...rest
}) => {
  return <AuthenticatedLayout {...rest}>{children}</AuthenticatedLayout>
}

interface AppLayoutProps {
  children: React.ReactNode
  /**
   * Array of paths that should render the public layout.
   */
  publicRoutes?: Array<string>
  /**
   * Render the public layout.
   */
  isPublic?: boolean
  /**
   * The layout to render.
   * Can be a component or build-in layout key `settings` | `fullscreen`
   */
  layout?: React.ReactNode
  /**
   * The sidebar component.
   */
  sidebar?: React.ReactElement
}

/**
 * Application layout
 * Handles rendering
 */
export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  publicRoutes = [],
  isPublic,
  layout,
  ...rest
}) => {
  const location = useLocation()
  const isPublicRoute =
    publicRoutes.indexOf(location.pathname) !== -1 || isPublic

  let LayoutComponent
  if (isPublicRoute) {
    LayoutComponent = PublicLayout
  } else if (typeof layout === 'function') {
    LayoutComponent = layout
  } else {
    switch (layout) {
      case 'settings':
        LayoutComponent = SettingsLayout
        break
      case 'fullscreen':
        LayoutComponent = FullscreenLayout
        break
      default:
        LayoutComponent = DefaultLayout
    }
  }

  return (
    <Flex
      position="absolute"
      w="100vw"
      h="100vh"
      fontSize="sm"
      flexDirection="column"
    >
      <LayoutComponent flex="1" minH="0" {...rest}>
        {children}
      </LayoutComponent>
    </Flex>
  )
}
